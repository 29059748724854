import React from "react";
import Carousel from "./includes/Carousel"

import mascoutelou from "../img/portfolio/mascoutelou.jpg";
import lousgrezes from "../img/portfolio/lousgrezes.jpg";
import radioanatomie from "../img/portfolio/radioanatomie.jpg";
import clairance from "../img/portfolio/clairance.jpg";
import vinsnaturels from "../img/portfolio/vinsnaturels.jpg";
import appli_pdc from "../img/portfolio/appli_pdc.jpg";
import optimisation from "../img/portfolio/optimisation.jpg";

const Accueil = (props) => {

  const candidates = [
    { id: 1, img: mascoutelou, semestre: 'Formula presi/vice', names: 'P1 y P2', description: 'Hola somos ...' },
    { id: 2, img: lousgrezes, semestre: 'Primer Semestre', names: 'S1', description: 'Hola soy ...' },
    { id: 3, img: radioanatomie, semestre: 'Segundo Semestre', names: 'S2', description: 'Hola soy ...' },
    { id: 4, img: clairance, semestre: 'Tercer Semestre', names: 'S3', description: 'Hola soy ...' },
    { id: 5, img: vinsnaturels, semestre: 'Cuarto Semestre', names: 'S4', description: 'Hola soy ...' },
    { id: 6, img: appli_pdc, semestre: 'Quinto Semestre', names: 'S5', description: 'Hola soy ...' },
    { id: 7, img: optimisation, semestre: 'Sexto Semestre', names: 'S6', description: 'Hola soy ...' }
  ];

  return (
    // <div id="home" className="intro route bg-image " style={{backgroundImage: "url("+bigImage+")"}}>
    <div id="Accueil" className="base">
      <div className="container center">
        <h1 className="orange">Prenez le contrôle de votre site internet</h1>

        <div className="col-sm-12">
          <div className="row">
            <div className="col-md-8">
              <Carousel autoplay={true} autoplay_speed={4500}>
                {candidates.map((slide) => {
                  return (
                    <div key={slide.id}>
                      <img src={slide.img} alt={slide.semestre} style={{ maxWidth: 500, }} />
                    </div>)
                })}
              </Carousel>
            </div>

            <div className="col-md-3" id="droite">
              <p>Réduction de 10% pour : </p>
              <h3 className="orange">Entreprises de proximité</h3>
              <ul>
                <li>&#149; Saint-Egrève (38120)</li>
                <li>&#149; Le Fontanil (38120)</li>
                <li>&#149; Saint-Martin le Vinoux (38120)</li>
              </ul>
              <br />
              <h3 className="vert">Les avantages</h3>
              <ul>
                <li>&#149; Prise en main totale de votre site</li>
                <li>&#149; Nombre de pages illimitées</li>
                <li>&#149; Version pour téléphone mobile</li>
              </ul>
              <br />
              <h3 className="bleu">En savoir plus</h3>
              <p className="f12">Contactez moi, le devis est gratuit et sans engagement.<br /> <a className="bleu" href="#contact">Formulaire de contact</a> </p>


            </div>
          </div>
        </div>

        <p className="vert">Créateur indépendant de site internet à Saint-Egrève (38120) dans l'agglomération de Grenoble.</p>
        <p>Les sites sont livrées avec une interface d'administration, vous avez donc la possibilité de créer, de modifier et de mettre à jour tout le contenu depuis n'importe quel ordinateur, n'importe quand et indéfiniment. </p>
        <p>Un seul interlocuteur, avec prise en charge totale et personnelle de votre projet : design, réalisation, support, en mettant l'effort sur le référencement (naturel et gratuit). A quoi ça sert d'avoir un beau site, si personne ne vient le voir !!!</p>
        <p>Que vous soyez un professionnel ou un particulier, je vous propose :</p>

        <div className="conteneur-flexible ligne">

          <div id="cgauche" className="element-flexible">
            <h5 className="rose">Création de votre site</h5>
            <ul>
              <li>Site vitrine simple</li>
              <li>Site complet, présentant votre entreprise sur quelques pages<br />
                <span className="fausse_liste">- Espace administration </span><br />
                <span className="fausse_liste">- Nombre de page illimité</span><br />
                <span className="fausse_liste">- Formulaire de contact</span><br />
                <span className="fausse_liste">- Adresse internet personnalisée</span><br />
              </li>
              <li>Site personnel<br />
                <span className="fausse_liste">- Pour mariage ou naissance </span><br />
              </li>
            </ul>
          </div>
          <div id="ccentre" className="element-flexible">
            <h5 className="vert">Référencement et compatibilité</h5>
            <ul>
              <li>Optimisation du code pour le référencement</li>
              <li>Travail pour le positionnement sur les principaux moteurs de recherche</li>
              <li>Compatibilité avec les différents navigateurs </li>
              <li>Sites adaptés aux téléphone mobiles</li>
            </ul>
          </div>
          <div id="cdroite" className="element-flexible">
            <h5 className="orange">Refonte de site</h5>
            <p>Vous avez déjà un site mais : </p>
            <ul>
              <li>Il ne vous convient pas ou plus</li>
              <li>Vous ne pouvez pas le mettre à jour</li>
              <li>Il n'est pas compatible avec les navigateurs récents</li>
              <li>Personne ne vient dessus</li>
            </ul>
            <br />
            <p>Refonte totale en gardant le contenu</p>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Accueil;
