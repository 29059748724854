import React from "react";
import {Link} from"react-router-dom"

const Application = (props) => {
  return (
    <section id="Realisations" className="portfolio-mf route">
      <div className="container">
        <div className="row">
          <div className="box-shadow-full">
            <div className="title-box text-center">
              <h3 className="title-a">Les applications disponibles</h3>
              <div className="line-mf"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="box-shadow-full">
          <div className="appli">
            <div className="appli_img">
              <img
                className="img-responsive"
                src="https://v2.webdoz.fr/appli/clairance_0.jpg"
                alt="Clairance"
              />
              <img
                className="img-responsive"
                src="https://v2.webdoz.fr/appli/clairance_1.jpg"
                alt="Clairance"
              />
            </div>
            <div className="appli_desc">
              <h2 className="title centre">Calcul de la Clairance de la Créatinine</h2>
              <div className="centre">
                <img  className="img-responsive" src="https://v2.webdoz.fr/appli/android.jpg" alt="android" />
                <img  className="img-responsive" src="https://v2.webdoz.fr/appli/ios.jpg" alt="ios" />
              </div>
              <div className="centre">
                <h3><br /> </h3>
                <Link eventKey="6" as={Link} className="nav-link js-scroll" to="/Contact">
                <h3>Contact </h3>
                </Link>
              </div>


            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Application;
