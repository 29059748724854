import React from "react";
import { HashLink as Link } from 'react-router-hash-link';
import { Nav, Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

//import stock
import mascoutelou from "../img/portfolio/mascoutelou.jpg";
import lousgrezes from "../img/portfolio/lousgrezes.jpg";
import radioanatomie from "../img/portfolio/radioanatomie.jpg";
import clairance from "../img/portfolio/clairance.jpg";
import vinsnaturels from "../img/portfolio/vinsnaturels.jpg";
import appli_pdc from "../img/portfolio/appli_pdc.jpg";
import optimisation from "../img/portfolio/optimisation.jpg";

const Realisations = (props) => {
  return (
    <section id="Realisations" className="portfolio-mf route">
      <div className="container">
        <div className="row">
          <div className="box-shadow-full">
            <div className="title-box text-center">
              <h3 className="title-a">Nos réalisations</h3>
              <p className="subtitle-a">
                Quelques unes de nos réalisations
              </p>
              <div className="line-mf"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="work-box">
              <a href="https://www.radioanatomie.com/" target="_blank" rel="noreferrer">
                <div className="work-img">
                  <img src={radioanatomie} alt="" className="img-fluid" />
                </div>
                <div className="work-content">
                  <div className="row">
                    <div className="col-sm-10">
                      <h2 className="w-title">Radioanatomie.com</h2>
                      <div className="w-more">
                        <span className="w-ctegory">

                        </span>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="w-like">
                        <FontAwesomeIcon icon={faPlusCircle} />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>


          <div className="col-md-4">
            <div className="work-box">
              <a href="https://mascoutelou.fr/" data-lightbox="gallery-aguadeluz">
                <div className="work-img">
                  <img src={mascoutelou} alt="" className="img-fluid" />
                </div>
                <div className="work-content">
                  <div className="row">
                    <div className="col-sm-10">
                      <h2 className="w-title">Mas Coutelou</h2>
                      <div className="w-more">
                        <span className="w-ctegory">

                        </span>{" "}
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="w-like">
                        <FontAwesomeIcon icon={faPlusCircle} />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>


          <div className="col-md-4">
            <div className="work-box">
              <a href="https://www.vinsnaturels.fr/" data-lightbox="gallery-todo">
                <div className="work-img">
                  <img src={vinsnaturels} alt="" className="img-fluid" />
                </div>
                <div className="work-content">
                  <div className="row">
                    <div className="col-sm-10">
                      <h2 className="w-title">Vinsnaturels.fr</h2>
                      <div className="w-more">
                        <span className="w-ctegory">

                        </span>
                        {/*} / <span className="w-date">18 Sep. 2018</span>*/}
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="w-like">
                        <FontAwesomeIcon icon={faPlusCircle} />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>


          <div className="col-md-4">
            <div className="work-box">
              <a href="https://lousgrezes.com/" data-lightbox="gallery-medlingos">
                <div className="work-img">
                  <img src={lousgrezes} alt="" className="img-fluid" />
                </div>
                <div className="work-content">
                  <div className="row">
                    <div className="col-sm-10">
                      <h2 className="w-title">Lous Grezes</h2>
                      <div className="w-more">
                        <span className="w-ctegory">
                          Site internet simple
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="w-like">
                        <FontAwesomeIcon icon={faPlusCircle} />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>


          <div className="col-md-4">
            <div className="work-box">
              <a href="https://clairance-creatinine.fr/" data-lightbox="gallery-smth">
                <div className="work-img">
                  <img src={clairance} alt="" className="img-fluid" />
                </div>
                <div className="work-content">
                  <div className="row">
                    <div className="col-sm-10">
                      <h2 className="w-title">Calcul de la Clairance</h2>
                      <div className="w-more">
                        <span className="w-ctegory">

                        </span>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="w-like">
                        <FontAwesomeIcon icon={faPlusCircle} />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>


          <div className="col-md-4">
            <div className="work-box">
              <div className="work-img">
                <img src={appli_pdc} alt="" className="img-fluid" />
              </div>
              <div className="work-content">
                <div className="row">
                  <div className="col-sm-10">
                    <h2 className="w-title">Opti Dose</h2>
                    <div className="w-more">
                      <span className="w-ctegory">
                        Application mobile
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="w-like">
                      <span className="ion-ios-more-outline"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="work-box">
              <a href="https://v2.clairance-creatinine.fr/Oncologie" data-lightbox="gallery-smth">
                <div className="work-img">
                  <img src={optimisation} alt="" className="img-fluid" />
                </div>
                <div className="work-content">
                  <div className="row">
                    <div className="col-sm-10">
                      <h2 className="w-title">Outil d'optimisation de dose</h2>
                      <div className="w-more">
                        <span className="w-ctegory">
                          REACT, NodeJS, MySQL
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="w-like">
                        <FontAwesomeIcon icon={faPlusCircle} />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <Nav.Link eventKey="3" as={Link} className="nav-link js-scroll" to="/Applications">Les applications</Nav.Link>

        </div>
      </div>
    </section>
  );
}

export default Realisations;
