import React from "react";
import { Routes, Route } from "react-router-dom";

//import css in order
import "bootstrap/dist/css/bootstrap.css";
import "./style.css";

import Navbar from "./pages/navbar.jsx";

import Accueil from "./pages/00_Accueil.jsx";
import Realisations from "./pages/01_Realisations.jsx";
import Contact from "./pages/03_Contact.jsx";
import Applications from "./pages/Applications";

import BackToTop from "./pages/back-top.jsx";
import Footer from "./pages/includes/Footer.jsx";

function App() {
  return (
    <div className="App">
      <Navbar />
      <div className="main">
        <Routes>
          <Route exact path="/" element={<Accueil />} />
          <Route exact path="/Realisations" element={<Realisations />} />
          <Route exact path="/Contact" element={<Contact />} />
          <Route exact path="/Applications" element={<Applications />} />
        </Routes>
      </div>
      <BackToTop />
      <Footer />
    </div>
  );
}

export default App;
