import React from "react";
import logo1 from "../img/webdoz.jpg";
import { HashLink as Link } from 'react-router-hash-link';
import { Nav, Navbar } from "react-bootstrap";

const Navbarr = (props) => {
    return (
      <Navbar collapseOnSelect expand="lg"
        className="navbar navbar-b navbar-trans navbar-expand-md fixed-top">
        <a className="navbar-brand js-scroll" href="">
          <img
            src={logo1}
            alt="logo"
            style={{ maxWidth: "75px" }}
          /><span className="hidden-xs">Création et gestion de sites web</span>
        </a>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse className="responsive-navbar-nav justify-content-end">
          <Nav className="mr-auto">
            <ul className="navbar-nav">
              <li className=" Accueil">
                <Nav.Link eventKey="1" as={Link} className="nav-link js-scroll" to="">
                  Accueil
                </Nav.Link>
              </li>
              <li className="Realisations">
                <Nav.Link eventKey="3" as={Link} className="nav-link js-scroll" to="Realisations">
                  Réalisations
                </Nav.Link>
              </li>

              <li className="Contact">
                <Nav.Link eventKey="6" as={Link} className="nav-link js-scroll" to="contact">
                  Contact
                </Nav.Link>
              </li>
            </ul>

          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
export default Navbarr;