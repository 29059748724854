import React, { useState, useEffect, useRef } from 'react'
import './carousel.css'
//import stock

const Carousel = (props) => {
    const { children } = props


    const [currentIndex, setCurrentIndex] = useState(0)
    const [length, setLength] = useState(children.length)


    const delay = 3000;
    const timerRef = useRef(null)
    const [current, setCurrent] = useState(0);
    const [auto, setAuto] = useState(true);

    useEffect(() => {
        setLength(children.length)
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            if (auto) {
                setCurrent(c => c + 1);
            }
        }, delay);
        return () => next(timerRef.current);
    }, [auto, current, children]);

    const next = () => {
        if (currentIndex < (length - 1)) {
            setCurrentIndex(prevState => prevState + 1)
        }
        if (currentIndex === (length - 1)) {
            setCurrentIndex(prevState => 0)
        }
    }

    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(prevState => prevState - 1)
            setAuto(false)
        }
        if (currentIndex === 0) {
            setCurrentIndex(prevState => length - 1)
            setAuto(false)
        }
    }

    return (
        <div className="carousel-container">
            <div className="carousel-wrapper">
                <button onClick={prev} className="left-arrow"> &lt; </button>
                <div className="carousel-content-wrapper">
                    <div className="carousel-content" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                        {children}
                    </div>
                </div>
                <button onClick={next} className="right-arrow"> &gt; </button>
            </div>
        </div>
    )
}

export default Carousel
