import React from "react";
import md5 from 'md5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

const Contact = (props) => {

  const nobot = '666'; // Set the value of $nobot here


  return (
    <section
      className=" bg-image background"
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="contact-mf">
              <div id="contact" className="box-shadow-full">
              <h5 className="title-a center">Contactez-nous</h5>
                      <div className="line-mf"></div>
                <div className="row">
                  <div className="col-md-8">
                    <div className="title-box-2">
                    </div>
                    <div>
                      <form
                        action="https://mail.webdoz.fr/"
                        method="POST"
                        className="contactForm"
                      >
                        <div id="sendmessage">
                          Votre message a été envoyé, mecol-sm-10rci !
                        </div>
                        <div id="errormessage"></div>
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <div className="form-group">
                              <input
                                type="text"
                                name="nom"
                                className="form-control"
                                id="nom"
                                placeholder="Votre nom"
                                data-rule="minlen:4"
                                data-msg="Merci d'entrer au moins 4 caractères"
                                required
                              />
                              <div className="validation"></div>
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                name="Email"
                                id="Email"
                                placeholder="Votre Email"
                                data-rule="email"
                                data-msg="Merci de mettre un mail valide"
                                required
                              />
                              <div className="validation"></div>
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                name="sujet"
                                id="sujet"
                                placeholder="Sujet"
                                data-rule="minlen:4"
                                data-msg="Au moins 4 lettres dans le sujet"
                                required
                              />
                              <div className="validation"></div>
                            </div>
                          </div>
                          <div className="col-md-12 mb-3">
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                name="message"
                                rows="5"
                                data-rule="required"
                                data-msg="Merci d'écrire un message"
                                placeholder="Message"
                                required
                              ></textarea>
                              <div className="validation"></div>
                            </div>
                          </div>
                          <input type="hidden" name="try" value="send_affiliation" />
                          <input type="hidden" name="nobotv" value={nobot} />
                          <div className="col-md-12">
                            <input type="checkbox" id="nobotc" name="nobotc" value={md5(nobot)} /> <label htmlFor="nobotc">
                              <b>  Je confirme que je suis un être humain, et pas un robot.</b>
                            </label>
                          </div>

                          <div style={{ position: 'absolute', visibility: 'hidden', left: '-5000', top: '-5000' }}>
                            <input type="checkbox" name="nobots" value={new Date().getTime()} />Robot
                          </div>



                          <div className="col-md-12 contact-mf">
                            <button
                              type="submit"
                              className="button button-a button-big button-rouded"
                            >
                              Envoyer
                            </button>







                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-md-4 center ">
                    <div className="">
                    </div>
                    <div className="socials">
                      <ul>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/3513767/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="ico-circle inv">
                            <FontAwesomeIcon icon={faLinkedin} />
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="more-info">
                      {<ul className="list-ico">
                        <li><span className="ion-ios-location"></span> Saint-Egrève, France</li>
                        <li><span className="ion-ios-telephone"></span> (+33) 6 15 03 81 40</li>
                      </ul>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="copyright-box">
              </div>
            </div>
          </div>
        </div>
      </footer>
    </section>
  );
}

export default Contact;
