import React from 'react'
import ReactGA from 'react-ga4'

const date = new Date()
const annee = date.getFullYear()

const Footer = (props) => {

    if (!process.env.HOST_BD) {
        // nous sommes en local
        //console.log("Local")
        ReactGA.initialize("G-3S01YTPLMX");
        ReactGA.send("pageview");
    } else {
        // nous sommes en ligne
        ReactGA.initialize("G-3S01YTPLMX");
        ReactGA.send("pageview");
    }


    return (
        <footer>
            <p>2009-{annee} | Weddoz création et gestion de site internet</p>
            <p>SIRET : 51747226200024</p>
        </footer>
    )
}
export default Footer